import React from "react";

const FeaturesContent = [
  {
    img: "94",
    title: "Bóc tách thông tin giấy tờ",
    desc: `Chứng minh thư cũ/mới, Căn cước công dân, căn cước công dân gắn chip, Hộ chiếu`,
  },
  {
    img: "95",
    title: "Đọc thông tin trong chip CCCD",
    desc: `Đọc thông tin trong thẻ chip của CCCD mới một cách chính xác thông qua công nghệ NFC`,
  },
  {
    img: "96",
    title: "Xác minh danh tính",
    desc: `Xác thực khuôn mặt qua video và giọng nói, với một số tính năng chống giả mạo`,
  },
  {
    img: "97",
    title: "Định danh công dân",
    desc: `Xác danh tính công dân thông qua kết nối CSDL quốc gia về dân cư`,
  },
  {
    img: "98",
    title: "Tích hợp dễ dàng",
    desc: `Hỗ trợ SDK cho các nền tảng Androi, IOS, React Native, Flutter, Web`,
  },
  {
    img: "99",
    title: "Hệ sinh thái xác thực số",
    desc: `Tháo gỡ toàn diện những rủi ro về giả mạo xác thực trong giao dịch điện tử`,
  },
];
const FancyFeatureSeventeen = () => {
  return (
    <>
      {FeaturesContent.map((val, i) => (
        <div
          className="col-lg-4 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          key={i}
        >
          <div className="block-meta">
            <div className="icon d-flex align-items-end">
              <img src={`images/icon/${val.img}.svg`} alt="icon" />
            </div>
            <h4>{val.title}</h4>
            <p>{val.desc}</p>
          </div>
        </div>
        // .block-meta
      ))}
    </>
  );
};

export default FancyFeatureSeventeen;
