import React from "react";
import FormEvent from "../form/FormEvent";
import { Link } from "react-router-dom";

const CallToActionFour = () => {
  return (
    <div className="row align-items-center">
      <div className="col-lg-6">
        <div className="title-style-one">
          <h6 className="font-rubik" style={{ color: "#787CFF" }}>
          Bạn muốn biết các thông tin mới về ID Check
          </h6>
          <h2>Đăng ký email</h2>
        </div>
        {/* /.title-style-one */}
      </div>
      <div className="col-lg-6">
        <div className="form-wrapper">
          <FormEvent />
          <p className="font-rubik">
          Bạn đã có tài khoản ID Check? <a href="https://console.idcheck.com.vn">Đăng nhập ngay</a>
          </p>
        </div>
        {/* /.form-wrapper */}
      </div>
    </div>
  );
};

export default CallToActionFour;
