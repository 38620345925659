import React from "react";
import FancyFeatureTewentyEight from "../features/FancyFeatureTewentyEight";
import FancyFeatureThirtyOne from "../features/FancyFeatureThirtyOne";

const featureList = [
  {
    icon: "157",
    title: "Drag Image & SVG",
    description: ` Create beautiful sites at breakneck speed without
    compromising on your creativit`,
  },
  {
    icon: "158",
    title: "Create Custom Field",
    description: `Keep your brand front & center with your customer`,
  },
  {
    icon: "159",
    title: "Custom Attribute",
    description: ` Increase your client retention rate by showing your results
    and value.`,
  },
];

const featureList1 = [
  {
    icon: "157",
    title: "Bước 1: chụp ảnh",
    description: `Chụp ảnh căn cước công dân gắn Chip`,
  },
  {
    icon: "158",
    title: "Bước 2: xác thực công dân",
    description: `Xác thực căn cước công dân gắn Chip với Bộ Công An`,
  },
  {
    icon: "159",
    title: "Bước 3: xác minh danh tính",
    description: `Xác thực khuôn mặt với dữ liệu ảnh trên CCCD`,
  },
];

const FancyTextBlock29 = () => {
  return (
    <div className="container">
      <div className="block-style-thirty bottom-border pb-80 mb-170 md-pb-40 md-mb-90">
        <div className="row">
          <div className="col-xl-5 col-lg-6">
            <div className="text-block md-pb-60">
              <div className="feature">Xác minh danh tính trên App</div>
              <div className="title-style-nine mb-60 md-mb-40">
                <h2>Nhận diện, bóc tách (OCR) được hầu hết các giấy tờ tùy thân</h2>
              </div>
              <p>
              Đảm bảo phát hiện hầu hết các trường hợp giấy tờ không đủ điều kiện đăng ký thông tin
              </p>
              <ul className="feature-list-two">
                <li>
                Phát hiện giấy tờ mờ nhòe, hỏng, bị cắt góc, tẩy xóa, v.v.
                </li>
                <li>
                Phát hiện giấy tờ có bị dán đè ảnh chân dung, giấy tờ hết hạn
                </li>
                <li>Phát hiện giấy tờ giả (trường hợp các ảnh đầu vào là chứng minh nhân dân photo, scan, v.v.</li>
              </ul>
              {/* End .feature-list-one */}

              {/*<a href="#" className="theme-btn-thirteen mt-20">
                Learn More
              </a> */}
            </div>
            {/* <!-- /.text-block --> */}
          </div>
          <div className="col-xl-7 col-lg-6">
            <img src="images/assets/screen_35_1.png" alt="screen" />
          </div>
        </div>
      </div>
      {/* <!-- /.block-style-thirty --> */}

      <div className="block-style-thirty">
        <div className="row">
          <div className="col-xl-5 col-lg-6 order-lg-last">
            <div className="text-block md-pb-60">
              <div className="feature">Định danh công dân</div>
              <div className="title-style-nine mb-45 md-mb-30">
                <h2>Đọc dữ liệu công dân trong thẻ chip của CCCD mới sử dụng NFC</h2>
              </div>
              <p>
                Tích hợp Cơ sở dữ liệu Quốc gia về dân cư để kiểm tra các trường thông tin công dân
              </p>
              {/*<a href="#" className="theme-btn-thirteen mt-30">
                Learn More
              </a>*/}
            </div>
            {/* <!-- /.text-block --> */}
          </div>
          <div className="col-xl-7 col-lg-6 order-lg-first">
            <img src="images/assets/screen_36_1.png" alt="screen" />
          </div>
        </div>
      </div>
      {/* <!-- /.block-style-thirty --> */}

      <div className="mt-80 pb-100 md-pb-80 bottom-border">
        <FancyFeatureTewentyEight />
      </div>

      <div className="block-style-thirty mt-150 md-mt-90">
        <div className="row">
          <div className="col-xl-5 col-lg-6">
            <div className="text-block md-pb-60">
              <div className="feature">Quy trình đơn giản</div>
              <div className="title-style-nine mb-45">
                <h2>Quy trình xác minh theo 3 bước</h2>
              </div>
              <ul className="feature-list-one">
                {featureList1.map((list, i) => (
                  <li key={i}>
                    <img
                      src={`images/icon/${list.icon}.svg`}
                      alt="icon"
                      className="icon"
                    />
                    <strong>{list.title}</strong>
                    <span>{list.description}</span>
                  </li>
                ))}
              </ul>
            </div>
            {/* <!-- /.text-block --> */}
          </div>
          <div className="col-xl-7 col-lg-6">
            <img src="images/assets/screen_37_2.png" alt="screen" />
          </div>
        </div>
      </div>
      {/* <!-- /.block-style-thirty --> */}

      <div className="mt-30">
        <FancyFeatureThirtyOne />
      </div>
    </div>
  );
};

export default FancyTextBlock29;
