import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const NotFound = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>ID Check | Not Found</title>
      </Helmet>
      {/* End Page SEO Content */}

      <div className="error-page d-lg-flex align-items-center">
        <div className="img-holder order-lg-last">
          <img
            src="images/media/404.svg"
            alt="media"
            className="w-100 illustration"
          />
          <img
            src="images/media/404-q.svg"
            alt="media"
            className="shapes qus"
          />
        </div>
        <div className="text-wrapper order-lg-first">
          <div className="logo">
            <Link to="/">
              <img style={{width:150}} src="images/logo/deski_06.svg" alt="logo" />
            </Link>
          </div>
          <h2 className="font-slab">
            Xin lỗi, <br />
            Chúng tôi không tìm thấy trang bạn yêu cầu
          </h2>
          <p className="font-rubik">
            Vui lòng quay trở về trang chủ hoặc liên hệ với chúng tôi để được hỗ trợ
          </p>

          <Link
            to="/"
            className="back-home font-rubik d-flex align-items-center justify-content-center"
          >
            <span>Về trang chủ</span>
            <img src="images/icon/53.svg" alt="icon" />
          </Link>
        </div>
        {/* /.text-wrapper */}
      </div>
      {/* /.error-page */}
    </div>
  );
};

export default NotFound;
