import React, {useState} from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";

const CallToActionThree = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const baseUrl = process.env.REACT_APP_API;
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  const handleSendEmail = (event) => {
    event.preventDefault();
    setLoading(true);
    axios.post(baseUrl+"/feedback/news",{email: email}).then((res) => {
      toast("Đăng ký nhận thông tin thành công");
      setLoading(false);
    }).catch((err) => {
      toast("Đăng ký nhận thông tin thất bại");
      setLoading(false);
    });
  };
  return (
    <div className="row align-items-center">
      <div className="col-lg-6">
        <div className="title-style-one">
          <h6 className="font-rubik" style={{ color: "#f96f60" }}>
            Bạn muốn biết các thông tin mới về ID Check
          </h6>
          <h2 className="font-gilroy-bold">Đăng ký email</h2>
        </div>
        {/* /.title-style-one */}
      </div>
      {/* End .col */}

      <div className="col-lg-6">
        <div className="form-wrapper">
          <form onClick={handleSubmit}>
            <input value={email} onChange={(e)=>{setEmail(e.target.value)}} type="text" placeholder="Địa chỉ email" />
            <button onClick={handleSendEmail}>Gửi</button>
          </form>
          {/* End form */}
          <p className="font-rubik">
            Bạn đã có tài khoản ID Check? <a href="https://console.idcheck.com.vn/login">Đăng nhập ngay</a>
          </p>
        </div>
        {/* /.form-wrapper */}
        {loading && <div className="text-sm mt-5" style={{color:'#FF006B'}}>Vui lòng đợi ...</div>}
        <ToastContainer position="bottom-right"
        autoClose={5000} closeButton={false}/>
      </div>
      {/* End .col */}
    </div>
  );
};

export default CallToActionThree;
