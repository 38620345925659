import React from "react";

const AboutFour = () => {
  return (
    <div className="row">
      <div className="col-lg-6" data-aos="fade-right" data-aos-duration="1200">
        <div className="text-wrapper">
          <div className="title-style-two">
            <p>ID Check</p>
            <h2 className="font-gilroy-bold">Giải pháp toàn diện về chống giả mạo xác thực số</h2>
          </div>
          {/* /.title-style-two */}
          <p className="sub-text">
          Bằng công nghệ trí tuệ nhân tạo tiên tiến của ID Check. Giúp tối ưu quy trình làm việc, tuân thủ các quy định KYC/AML và làm hài lòng khách hàng
          </p>
        </div>
      </div>
      {/* End .col */}

      <div className="col-lg-6" data-aos="fade-left" data-aos-duration="1200">
        <div className="img-holder md-mt-80">
          <img src="images/media/img_39.png" alt="media" />
          <img
            src="images/shape/60.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/61.svg"
            alt="shape"
            className="shapes shape-two"
          />
        </div>
      </div>
      {/* End .col */}
    </div>
  );
};

export default AboutFour;
