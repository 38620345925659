import React from "react";

const socialContent = [
  {
    icon: "fa-facebook",
    link: "https://www.facebook.com/",
  },
  {
    icon: "fa-twitter",
    link: "https://twitter.com/",
  },
  {
    icon: "fa-linkedin",
    link: "https://www.linkedin.com/",
  },
];

const ContactAddress = () => {
  return (
    <div className="row justify-content-center">
      <div className="col-lg-6 col-sm-6 d-lg-flex">
        <div className="address-info">
          <div className="icon d-flex align-items-end">
            <img src="images/icon/44.svg" alt="icon" />
          </div>
          <div className="title">Địa chỉ</div>
          <p className="font-rubik">
          17 LK 16, khu đô thị mới Văn Khê <br /> Phường La Khê, Quận Hà Đông
          </p>
        </div>{" "}
        {/* /.address-info  */}
      </div>
      {/* End .col */}

      <div className="col-lg-6 col-sm-6 d-lg-flex">
        <div className="address-info">
          <div className="icon d-flex align-items-end">
            <img src="images/icon/45.svg" alt="icon" />
          </div>
          <div className="title">Contact</div>
          <p className="font-rubik">
            hello@idcheck.com.vn <br />
            (024) 39.119.119 / (028)39.088.688
          </p>
        </div>{" "}
        {/* /.address-info  */}
      </div>
      {/* End .col */}
      {/* End .col */}
    </div>
  );
};

export default ContactAddress;
