import React from "react";

const PricingContent = [
  {
    packName: "IDCheckPOC",
    price: "900 k",
    packageFor: "Gói thử nghiệm",
    icon: "38",
    billCycle: "Billed per agent",
    preFeatures: [
      "Sử dụng 100 requests",
      "Cung cấp API/SDK NFC đọc thẻ chip",
      "Xác thực CCCD gắn chip chính xác 100% với RAR-C06",
    ],
  },
  {
    packName: "IDCheck1",
    price: "8.5 triệu",
    packageFor: "Gói starter",
    icon: "40",
    billCycle: "Billed per agent",
    preFeatures: [
      "Sử dụng 100 requests",
      "Cung cấp API/SDK NFC đọc thẻ chip",
      "Xác thực CCCD gắn chip chính xác 100% với RAR-C06",
    ],
  },
  {
    packName: "IDCheck2",
    price: "40.5 triệu",
    packageFor: "Gói startup",
    icon: "41",
    billCycle: "Billed per agent",
    preFeatures: [
      "Sử dụng 5.000 requests",
      "Tiết kiệm 9% chi phí trên 1 request",
      "Cung cấp API/SDK NFC đọc thẻ chip",
      "Xác thực CCCD gắn chip chính xác 100% với RAR-C06",
    ],
  },
  {
    packName: "IDCheck3",
    price: "78 triệu",
    packageFor: "Gói tiêu chuẩn",
    icon: "41",
    billCycle: "Billed per agent",
    preFeatures: [
      "Sử dụng 10.000 requests",
      "Tiết kiệm 13% chi phí trên 1 request",
      "Cung cấp API/SDK NFC đọc thẻ chip",
      "Xác thực CCCD gắn chip chính xác 100% với RAR-C06",
      "Hỗ trợ dịch vụ thường xuyên"
    ],
  },
];

const PricingMonthly = () => {
  return (
    <div className="row justify-content-center">
      {PricingContent.map((val, i) => (
        <div className="col-lg-6 col-md-6" key={i}>
          <div className="pr-table-wrapper" style={{marginBottom:30}}>
            <div className="pack-name">{val.packName}</div>
            <div className="price font-rubik">
              {val.price} VNĐ
            </div>
            <div className="pack-rec font-rubik">{val.packageFor}</div>
            <img
              src={`images/icon/${val.icon}.svg`}
              alt="icon"
              className="icon"
            />
            <ul className="pr-feature pe-2">
              {val.preFeatures.map((list, i) => (
                <li key={i}>{list}</li>
              ))}
            </ul>
            <a href="#" className="theme-btn-four">
              Liên hệ
            </a>
          </div>
          {/*  /.pr-table-wrapper */}
        </div>
      ))}
    </div>
  );
};

export default PricingMonthly;
