import React from "react";

const featuresContent = [
  {
    icon: "163",
    title: "Khối doanh nghiệp bảo hiểm",
    desc: `Xác minh GTTT khi mở thẻ bảo hiểm Online<br>
    Xác minh khi sử dụng các dịch vụ chăm sóc sức khỏe, bảo hiểm
    `,
    delayAnimation: "",
  },
  {
    icon: "164",
    title: "Khối doanh nghiệp chức khoán",
    desc: `Xác minh thông tin khi mở tài khoản giao dịch<br>
    Xác minh thông tin giao dịch trực tuyến`,
    delayAnimation: "100",
  },
  {
    icon: "165",
    title: "Khối tài chính, ngân hàng",
    desc: `Xác minh khi mở tài khoản Online<br> 
    Xác minh khi vay tiêu dùng online/ online offline: F88, cầm đồ, FE…`,
    delayAnimation: "200",
  },
];

const FancyFeatureThirtyOne = () => {
  return (
    <div className="row justify-content-between">
      {featuresContent.map((item, i) => (
        <div
          className="col-lg-4 col-md-6"
          data-aos="fade-up"
          data-aos-delay={item.delayAnimation}
          key={i}
        >
          <div className="block-style-thirtyOne mt-40">
            <div className="icon d-flex align-items-end">
              <img src={`images/icon/${item.icon}.svg`} alt="icon" />
            </div>
            <h4>{item.title}</h4>
            <p dangerouslySetInnerHTML={{__html:item.desc}}></p>
          </div>
          {/* <!-- /.block-style-thirtyOne --> */}
        </div>
      ))}
    </div>
  );
};

export default FancyFeatureThirtyOne;
