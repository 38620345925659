import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import HeaderTwo from "../../../../components/header/Header";
import CallToActionThree from "../../../../components/call-to-action/CallToActionThree";
import FooterSeven from "../../../../components/footer/FooterSeven";
import CopyRightThree from "../../../../components/footer/CopyRightThree";
import CounterThreeColored from "../../../../components/counter/CounterThreeColored";
import FancyTextBlockTen from "../../../../components/fancy-text-block/FancyTextBlockTen";
import FancyFeatureSeven from "../../../../components/features/FancyFeatureSeven";
import TeamFour from "../../../../components/team/TeamFour";

const CustomerSupport = () => {
  return (
    <div className="main-page-wrapper">
      <Helmet>
        <title>
          ID Check | Về chúng tôi
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      {/* =============================================
		   Start Header
		   ============================================== */}
      <HeaderTwo />
      {/* End HeaderTwo Variation Three */}

      {/* 	=============================================
				Fancy Hero Two
			==============================================  */}
      <div className="fancy-hero-two">
        <div className="bg-wrapper">
          <div className="container">
            <div className="page-title">ID Check</div>
            <div className="row">
              <div className="col-xl-8 col-lg-10 col-md-10 m-auto">
                <h1 className="heading font-gilroy-bold">Giải pháp toàn diện về chống giả mạo xác thực số</h1>
                <p className="sub-heading">
                Xác minh danh tính điện tử nhanh chóng, chính xác & an toàn
                </p>
              </div>
            </div>
          </div>
          {/* End .container */}
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* /.fancy-hero-two */}

      {/* =============================================
				Fancy Text block Nine
			==============================================  */}
      <div className="fancy-text-block-nine mt-130 md-mt-80">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="container">
          <div className="title-style-two text-center mb-35 md-mb-10">
            <div className="row">
              <div className="col-lg-10 m-auto">
                <p>Giới thiệu chung</p>
                <h2 className=" font-gilroy-bold">Công ty cổ phần LTC</h2>
              </div>
            </div>
          </div>
          {/* End title-style-two */}

          <div className="row">
            <div className="col-xl-10 m-auto">
              <p
                className="text-meta"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                Là thành viên của Tập đoàn Bưu chính Viễn thông Việt Nam, hoạt động trong lĩnh vực thi công xây lắp các công trình bưu chính viễn thông, tin học, điện nhẹ và các công trình công nghiệp, dân dụng, cầu, đường bộ…, Công ty cổ phần LTC đã khẳng định vững chắc tên tuổi của mình trên thị trường cả nước sau hơn 20 năm xây dựng và trưởng thành với những thành tích nổi bật: được Tập đoàn Bưu chính Viễn thông Việt Nam liên tục tặng cờ thi đua và bằng khen thực hiện xuất sắc nhiệm vụ sản xuất kinh doanh, được Ủy ban nhân dân TP Hà Nội tặng bằng khen thi công công trình đảm bảo tiến độ, chất lượng chào mừng kỷ niệm 1000 năm Thăng Long – Hà Nội…
              </p>
              <p
                className="text-meta"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                Hướng tới mục tiêu phát triển trong tương lai, Công ty chúng tôi đang nỗ lực không ngừng để mở rộng thị trường, nâng cao tốc độ phát triển của Công ty và đem đến sự hài lòng cho Quý khách hàng nhiều hơn nữa. Chúng tôi hiểu rằng sự quan tâm, hỗ trợ từ Quý khách hàng là nhân tố quan trọng cho sự phát triển của Công ty chúng tôi trong thời gian qua cũng như trong tương lai.
              </p>
            </div>
          </div>
          {/* End .row */}
        </div>
      </div>
      {/* /.fancy-text-block-nine */}

      {/* =====================================================
				Counter With Icon One
			===================================================== */}
      <div className="counter-with-icon-one border-style pt-120 pb-45 md-pt-70">
        <div className="container">
          <div className="border-style">
            <CounterThreeColored />
          </div>
        </div>
        {/* End .container */}
      </div>
      {/*  /.counter-with-icon-one */}

      {/* 	=============================================
				Fancy Text block Ten
			==============================================  */}
      <div className="fancy-text-block-ten mt-120 md-mt-60">
        <div className="container">
          <FancyTextBlockTen />
        </div>
      </div>
      {/* /.fancy-text-block-ten */}

      {/* =====================================================
				Fancy Feature Five
			===================================================== */}
      <div className="d-none fancy-feature-five mt-200 md-mt-100">
        <div className="bg-wrapper">
          <div className="shapes shape-one"></div>
          <div className="shapes shape-two"></div>
          <div className="shapes shape-three"></div>
          <div className="shapes shape-four"></div>
          <img
            src="images/shape/47.svg"
            alt="shape"
            className="shapes shape-five"
          />
          <div className="container">
            <div className="title-style-two text-center mb-60 md-mb-40">
              <div className="row">
                <div className="col-xl-8 col-lg-9 m-auto">
                  <p>Our Values</p>
                  <h2>We’r driven by our values</h2>
                  <div className="sub-text">
                    Deski stand with friendly interface with lots of features
                    that help our team and csutomer to collbarate easily.
                  </div>
                </div>
              </div>
            </div>
            {/* End .title-style-two */}
            <div className="row justify-content-center">
              <FancyFeatureSeven />
            </div>
            {/* End .row */}
          </div>
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* /.fancy-feature-five */}

      {/* =====================================================
				Team Section Two
			===================================================== */}
      <div className="d-none team-section-two mt-200 md-mt-100">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="container">
          <div className="row align-items-center justify-content-between mb-80 md-mb-10">
            <div className="col-lg-8">
              <div className="title-style-two">
                <p>Our Team</p>
                <h2>Our talented team member waiting to server.</h2>
              </div>
            </div>
            {/* End .col */}
            <div className="col-lg-4 d-lg-flex justify-content-end">
              <Link to="/team-3" className="theme-btn-three md-mt-20">
                See All Members
              </Link>
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
          <div className="row">
            <TeamFour />
          </div>
          {/* End .row */}
        </div>
      </div>
      {/* /.team-section-two */}

      {/* 	=====================================================
			Fancy Short Banner Three
			===================================================== */}
      <div className="fancy-short-banner-three mt-225 md-mt-150">
        <div className="container">
          <div className="bg-wrapper">
            <CallToActionThree />
          </div>
          {/* /.bg-wrapper */}
        </div>
        {/* /.container */}
      </div>
      {/* /.fancy-short-banner-four */}

      {/* 	=====================================================
				Footer Style Two
			===================================================== */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <FooterSeven />
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default CustomerSupport;
