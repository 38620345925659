import React, {useState} from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FormEvent = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const baseUrl = process.env.REACT_APP_API;
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  const handleSendEmail = (event) => {
    event.preventDefault();
    setLoading(true);
    axios.post(baseUrl+"/feedback/news",{email: email},{
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    }).then((res) => {
      toast("Đăng ký nhận thông tin thành công");
      setLoading(false);
    }).catch((err) => {
      toast("Đăng ký nhận thông tin thất bại");
      setLoading(false);
    });
  };
  return (
    <form onClick={handleSubmit}>
      <input value={email} onChange={(e)=>{setEmail(e.target.value)}} type="text" placeholder="Địa chỉ email" />
      <button onClick={handleSendEmail}>Gửi</button>
      {loading && <div className="text-sm mt-5" style={{color:'#FF006B'}}>Vui lòng đợi ...</div>}
      <ToastContainer position="bottom-right"
      autoClose={5000} closeButton={false}/>
    </form>
  );
};

export default FormEvent;
